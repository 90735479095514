import React from 'react';
import {UsersService} from '../api/generated';
import {useUser} from '../auth/use-auth';
import {Form} from '../forms';
import {notifications} from '../utils/notification-service';

export const ChangePassword = ({onComplete}) => {
  const user = useUser();
  const id = user.id;

  const onSubmit = async values => {
    const response = await UsersService.updatePassword({
      id,
      body: {
        currentPassword: values.currentPassword || '',
        newPassword: values.newPassword || '',
        confirmNewPassword: values.confirmNewPassword || '',
      },
    });

    if (response.hasErrors) {
      return response;
    }

    notifications.success('Password Changed');

    if (onComplete) {
      onComplete();
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={() => (
        <>
          <Form.Input
            fieldName="currentPassword"
            fieldLabel="Current Password"
            type="password"
          />
          <Form.Input
            fieldName="newPassword"
            fieldLabel="New Password"
            fieldHint="Min Length: 8 Characters. At least one uppercase, lowercase and symbol required."
            type="password"
          />
          <Form.Input
            fieldName="confirmNewPassword"
            fieldLabel="Confirm New Password"
            type="password"
          />

          <div className="form-actions">
            <Form.Button type="submit" primary>
              Change Password
            </Form.Button>
            <Form.Button type="button" secondary onClick={onComplete}>
              Cancel
            </Form.Button>
          </div>
        </>
      )}
    />
  );
};
