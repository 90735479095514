import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Popup, PopupProps} from 'semantic-ui-react';
import {Tooltip} from './tooltip';
import {Button} from '../forms/button';

type ConfirmButton = Omit<Button, 'type' | 'as'> & {
  onConfirm: () => void;
  loading?: boolean;
  text?: string;
  tooltip?: string;
  confirmPosition?: PopupProps['position'];
  loadingText?: string;
  confirmText?: string;
  icon?: IconDefinition;
};

export const ConfirmButton: React.FC<ConfirmButton> = ({
  onConfirm,
  loading,
  text,
  loadingText = 'Loading...',
  confirmText = 'Are you sure?',
  confirmPosition = 'right center',
  icon,
  tooltip = '',
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onConfirmClick = () => {
    onConfirm();
    onClose();
  };

  return (
    <Popup
      open={open}
      onClose={onClose}
      trigger={
        <Tooltip label={tooltip}>
          <Button
            className="clear"
            basic
            type="button"
            icon={!!icon}
            disabled={loading}
            {...props}
            onClick={toggleOpen}
          >
            {!!icon && (
              <>
                <FontAwesomeIcon icon={icon} />{' '}
              </>
            )}
            <span>{loading ? loadingText : text}</span>
          </Button>
        </Tooltip>
      }
      content={
        <Button
          type="button"
          onClick={onConfirmClick}
          disabled={loading}
          primary
        >
          {confirmText}
        </Button>
      }
      position={confirmPosition}
      on="click"
    />
  );
};
