import mitt from 'mitt';
import {useEffect} from 'react';

let emitter = mitt();

type SubscriptionEventMap = {
  'session-expired': undefined;
  'organization-updated': {organizationId: number};
  __NEVER__: {};
};

export type SubscriptionEvents = keyof SubscriptionEventMap;

export function useSubscription<K extends SubscriptionEvents>(
  eventName: K,
  cb: (event: SubscriptionEventMap[K]) => void
): void {
  useEffect(() => {
    emitter.on(eventName, cb);
    return () => emitter.off(eventName, cb);
  });
}

export function notify<K extends SubscriptionEvents>(
  eventName: K,
  event: SubscriptionEventMap[K]
) {
  if (eventName === '__NEVER__') {
    throw Error('Never call this event...');
  }
  emitter.emit(eventName, event);
}
