import {Env} from '../config/env-vars';

type LogFunction = (message: string, ...optionalParams: any[]) => void;

type Logger = {
  debug: LogFunction;
  info: LogFunction;
  warn: LogFunction;
  error: LogFunction;
};

type levels = 'debug' | 'info' | 'warn' | 'error';

if (!Env.isProductionBuild) {
  try {
    localStorage.setItem('debug', 'true');
  } catch (error) {}
}

const logEnabled = localStorage.getItem('debug');

const createLogger = (namespace, subject, level: levels) => {
  return (message: string, ...optionalParams: any[]) => {
    // eslint-disable-next-line no-restricted-syntax
    const log = console[level] || console.log;

    if (!logEnabled) return;

    log.call(
      console,
      `${namespace}:${subject}:${level} => ${message}`,
      ...optionalParams
    );
  };
};

const DEFAULT_NAMESPACE = 'Elevator3.Billing';

export const logger: (namespace, config?) => Logger = (
  subject,
  config = {
    namespace: DEFAULT_NAMESPACE,
  }
) => {
  const namespace = config.namespace || DEFAULT_NAMESPACE;
  return {
    debug: createLogger(namespace, subject, 'debug'),
    info: createLogger(namespace, subject, 'info'),
    warn: createLogger(namespace, subject, 'warn'),
    error: createLogger(namespace, subject, 'error'),
  };
};

const developmentLogger = logger('development');

export {developmentLogger as debug};
