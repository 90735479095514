import {createBreakpoint} from 'react-use';

type Breakpoints = keyof typeof Breakpoints;
export const Breakpoints = {
  MobileMax: 767,
  TabletMin: 768,
  TabletMax: 1023,
  DektopMin: 1024,
  DektopMax: 1279,
  WideMin: 1280,
} as const;

export const MediaSizes = {
  MobileMax: `max-width: ${Breakpoints.MobileMax}px`,
  TabletMin: `min-width: ${Breakpoints.TabletMin}px`,
  TabletMax: `max-width: ${Breakpoints.TabletMax}px`,
} as const;

export const Media = (size: keyof typeof MediaSizes) =>
  `@media only screen and (${MediaSizes[size]})`;

export const useBreakpoint = () => {
  return createBreakpoint(Breakpoints)() as Breakpoints;
};
