import React from 'react';
import {Menu} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

export const NavigationLink = ({partial = false, ...props}) => (
  <Menu.Item
    as={Link}
    getProps={({isCurrent, isPartiallyCurrent}) => {
      const isActive = partial ? isPartiallyCurrent : isCurrent;
      return {
        className: isActive ? 'item active' : 'item',
      };
    }}
    {...props}
  />
);
