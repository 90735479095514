import React from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {RateTableTiersService} from '../api/generated';
import {States} from '../api/generated/enums';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DeleteButton} from '../components/confirm-delete-button';
import {Form} from '../forms';
import {useNotification} from '../hooks/use-notifications';
import {routes} from '../routes';
import {useOrganizationContext} from '../selectors';
import {notify} from '../hooks/use-subscription';

export const RateTableTierCreate = () => {
  const history = useHistory();
  const notifications = useNotification();
  const context = useOrganizationContext();

  const breadcrumbs = [
    {title: 'Rate Tiers', url: routes.rateTableTiers.listing},
    {title: 'Create'},
  ];

  const onSubmit = async values => {
    values.organizationId = context.organizationId;

    const response = await RateTableTiersService.create({body: values});
    if (response.hasErrors) {
      return response;
    }

    notifications.success('Rate Tier Created');
    history.push(routes.rateTableTiers.listing);
  };

  return (
    <BasicPage title={breadcrumbs}>
      <Form.Container>
        <Form
          onSubmit={onSubmit}
          render={() => (
            <>
              <Form.Section title="Rate Tier Information">
                <FormFields />
              </Form.Section>
              <div className="form-actions">
                <Form.Button type="submit" primary>
                  Create Tier
                </Form.Button>
                <Form.Button
                  secondary
                  as={Link}
                  to={routes.rateTableTiers.listing}
                >
                  Cancel
                </Form.Button>
              </div>
            </>
          )}
        />
      </Form.Container>
    </BasicPage>
  );
};

export const RateTableTierDetails = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const notifications = useNotification();

  const fetchRateTableTier = useAsync(async () => {
    const {data} = await RateTableTiersService.getById({
      id: id,
    });
    return data;
  }, [id]);

  const rateTableTier = fetchRateTableTier.value || undefined;

  //const userName = user ? `${user.firstName} ${user.lastName}` : '';

  const breadcrumbs = [
    {title: 'Rate Tiers', url: routes.rateTableTiers.listing},
    {title: rateTableTier ? rateTableTier.startingAmount.toString() : ''},
  ];

  const onSubmit = async values => {
    const response = await RateTableTiersService.update({
      id: id,
      body: values,
    });
    if (response.hasErrors) {
      return response;
    }
    notifications.success('Rate Tier Updated');
    history.push(routes.rateTableTiers.listing);
  };

  const [deleteRateTableTierState, deleteRateTableTier] = useAsyncFn(
    async () => {
      const response = await RateTableTiersService.deleteById({
        id: id,
      });
      if (response.hasErrors) {
        var errorMessage = response.errors.reduce((acc, item) => {
          return `${acc} \n ${item.errorMessage}`;
        }, '');

        notifications.error(errorMessage);
      } else {
        notifications.success('Rate Tier successfully deleted');
        history.push(routes.rateTableTiers.listing);
      }
    }
  );

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchRateTableTier}>
        <Form.Container>
          <Form
            initialValues={rateTableTier}
            onSubmit={onSubmit}
            render={({values}) => (
              <>
                <Form.Section title="Rate Tier Information">
                  <FormFields />
                </Form.Section>
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Update Tier
                  </Form.Button>
                  <Form.Button
                    secondary
                    as={Link}
                    to={routes.rateTableTiers.listing}
                  >
                    Cancel
                  </Form.Button>

                  <DeleteButton
                    onConfirm={deleteRateTableTier}
                    loading={deleteRateTableTierState.loading}
                    tertiary
                    icon={false}
                  />
                </div>
              </>
            )}
          />
        </Form.Container>
      </AsyncStateContainer>
    </BasicPage>
  );
};

const FormFields = () => {
  return (
    <>
      <Form.Row>
        <Form.Input
          type="number"
          fieldName="startingAmount"
          fieldLabel="Starting Gallon Amount"
        />
        <Form.Input
          type="number"
          fieldName="endingAmount"
          fieldLabel="Ending Gallon Amount"
        />
      </Form.Row>

      <Form.Section title="Cost">
        <Form.Row>
          <Form.InputCurrency
            type="number"
            fieldName="costPerIncrement.value"
            fieldLabel="Cost Per Gallons"
          />
          <div style={{margin: 'auto', paddingTop: '8px'}}>x</div>
          <Form.Input
            type="number"
            fieldName="costIncrement"
            fieldLabel="Gallons"
          />
          <div style={{margin: 'auto', paddingTop: '10px'}}>+</div>
          <Form.InputCurrency
            type="number"
            fieldName="fixedCost.value"
            fieldLabel="Fixed Price"
          />
        </Form.Row>
      </Form.Section>
    </>
  );
};
