import {useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';

export function useDebouncedState<T>(defaultValue: T, delay) {
  const [value, setValue] = useState(defaultValue);
  const [debouncedSetValue] = useDebouncedCallback(value => {
    setValue(value);
  }, delay);

  return [value, debouncedSetValue] as [T, (newValue: T) => void];
}
