import css from '@emotion/css/macro';
import React from 'react';
import {Loader} from 'semantic-ui-react';
import {Alert} from './alert';
import {DelayedLoadingContainer} from './delayed-loading-container';

type AsyncStateContainer = {
  loading: boolean;
  error?: Error;
  delayInMs?: number;
  children: any;
};

export const AsyncStateContainer = ({
  loading,
  error,
  children,
  delayInMs,
}: AsyncStateContainer) => {
  if (loading) {
    return (
      <DelayedLoadingContainer delayInMs={delayInMs}>
        <div
          css={css`
            margin-top: 20px;
          `}
        >
          <Loader inline="centered" active />
        </div>
      </DelayedLoadingContainer>
    );
  }

  if (error) {
    return <Alert negative>{error.message}</Alert>;
  }

  return children;
};
