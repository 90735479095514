import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {RateTableTiersService, RateTableTierSummaryDto} from '../api/generated';
import {BasicPage} from '../basic-page';
import {routes} from '../routes';
import {
  PagedDataTableConfig,
  usePagedDataTable,
  renderEditButton,
} from '../hooks/use-paged-data-table';
import {AdvancedPagedRequest} from '../types';
import {useOrganizationContext} from '../selectors';

type FetchParams = Parameters<
  typeof RateTableTiersService.getAllByOrganization
>['0'];

export const RateTableTierListing = () => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const additionalParams = useMemo<FetchParams>(
    () => ({
      organizationId: organizationId,
    }),
    [organizationId]
  );

  const fetch = useCallback(
    (x: AdvancedPagedRequest<RateTableTierSummaryDto>) =>
      RateTableTiersService.getAllByOrganization({
        ...additionalParams,
        ...x,
      } as FetchParams),
    [additionalParams]
  );

  const pagedDataTable = usePagedDataTable(fetch, TableConfig, {
    actions: (
      <Button as={Link} to={routes.rateTableTiers.create} primary>
        <FontAwesomeIcon icon={faPlus} /> New Tier
      </Button>
    ),
  });

  return <BasicPage title="Rate Tiers">{pagedDataTable}</BasicPage>;
};

const TableConfig = PagedDataTableConfig(
  RateTableTiersService.getAllByOrganization,
  {
    columns: [
      {
        header: '',
        render: item => (
          <>
            {renderEditButton({
              item,
              descriptor: 'rate table tier',
              route: routes.rateTableTiers.detail,
            })}
          </>
        ),
        cellProps: {
          collapsing: true,
        },
      },
      {
        header: 'Starting Amount',
        column: 'startingAmount',
        sortable: 'startingAmountForSorting',
      },
      {
        header: 'Ending Amount',
        column: 'endingAmount',
      },
      {
        header: 'Cost',
        render: item => (
          <>
            {item.fixedCost && item.fixedCost !== '$0.00' && (
              <div>
                <span style={{fontWeight: 'bold'}}>{item.fixedCost}</span> fixed
                price
              </div>
            )}
            {item.costPerIncrement && item.costPerIncrement !== '$0.00' && (
              <div>
                {' '}
                <span style={{fontWeight: 'bold'}}>
                  {item.costPerIncrement}
                </span>{' '}
                per{' '}
                <span style={{fontWeight: 'bold'}}>{item.costIncrement} </span>
                gallons
              </div>
            )}
          </>
        ),
      },
      {
        header: 'Total Due at Max',
        column: 'maxCostForTier',
      },
    ],
    searchFieldNames: ['startingAmount', 'endingAmount'],
    defaultSort: {
      column: 'startingAmountForSorting',
      direction: 'ASC',
    },
  }
);
