import React from 'react';
import {SettingsService} from '../api/generated';
import {BasicPage} from '../basic-page';
import {routes} from '../routes';
import {
  PagedDataTableConfig,
  renderEditButton,
  usePagedDataTable,
} from '../hooks/use-paged-data-table';

export const SettingListing = () => {
  const pagedDataTable = usePagedDataTable(SettingsService.getAll, TableConfig);
  return <BasicPage title="Settings">{pagedDataTable}</BasicPage>;
};

const TableConfig = PagedDataTableConfig(SettingsService.getAll, {
  columns: [
    {
      header: '',
      render: item =>
        renderEditButton({
          item,
          descriptor: 'setting',
          route: routes.settings.detail,
        }),
      cellProps: {
        width: '1',
      },
    },
    {
      header: 'Key',
      column: 'key',
    },
    {
      header: 'Value',
      column: 'value',
      copy: true,
    },
  ],
  defaultSort: {
    column: 'key',
    direction: 'ASC',
  },
  searchFieldNames: ['key', 'value'],
});
