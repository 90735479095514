import {useSelector} from 'react-redux';
import {StoreState, OrganizationContext} from './store';

export function useGlobalState<T>(cb: (state: StoreState) => T) {
  return useSelector((state: StoreState) => cb(state));
}

export const useOrganizationContext = () =>
  useGlobalState(state => state.global.context.value as OrganizationContext);

export const useOrganizations = () =>
  useGlobalState(state => state.global.organizations);
