/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {BasicPage} from './basic-page';
import {
  fieldConfig,
  input,
  dropdown,
  textarea,
  datepicker,
} from './forms/schema-utils';
import {Form} from './forms';
import {Json} from './components/json';
import {Timezones} from './api/generated/enums';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {timeValueConverter} from './forms/value-converters';
import {Divider} from 'semantic-ui-react';

const grid = 8;

const INITIAL_VALUES = {
  executionTime: '20:00:00',
  timezone: Timezones['US/Central'],
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: `${grid * 2} 0`,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? 'lightgreen' : '#FFF',
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : '#FFF',
  width: 'auto',
});

const makeOnDragEndFunction = fields => result => {
  // dropped outside the list
  if (!result.destination) {
    return;
  }

  fields.swap(result.source.index, result.destination.index);
};

const fields = fieldConfig({
  name: input({
    fieldLabel: 'Job Name',
  }),
  timezone: dropdown({
    fieldLabel: 'Timezone',
    inputProps: {
      enum: Timezones,
      selection: true,
      clearable: true,
    },
  }),
  startDate: datepicker({
    fieldLabel: 'Start Date',
    inputProps: {
      showClearDate: true,
    },
  }),
  executionTime: input({
    fieldLabel: 'Execution Time',
    inputProps: {
      valueConverter: timeValueConverter,
    },
  }),
  notes: textarea({
    fieldLabel: 'Notes',
  }),
});

let nextId = 1;

export const FormsExample = () => {
  return (
    <BasicPage title="Forms Example">
      <Form.Container>
        <Form
          initialValues={INITIAL_VALUES}
          onSubmit={() => {}}
          mutators={{
            ...arrayMutators,
          }}
          render={({values}) => (
            <>
              <Form.Section title="Job Config">
                <Form.Row>
                  <Form.Input fieldConfig={fields.name} />
                  <div className="form-field"></div>
                </Form.Row>
                <Form.Row>
                  <Form.DatePicker fieldConfig={fields.startDate} />
                  <Form.Input fieldConfig={fields.executionTime} />
                  <Form.Dropdown fieldConfig={fields.timezone} />
                </Form.Row>
              </Form.Section>
              <Form.Section title="Fields">
                <FieldArray name="records">
                  {({fields: records}) => (
                    <>
                      <DragDropContext
                        onDragEnd={makeOnDragEndFunction(records)}
                      >
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                              {...provided.droppableProps}
                            >
                              {records.map((name, index) => {
                                const key = `${records.value[index].id}`;

                                return (
                                  <Draggable
                                    key={key}
                                    draggableId={key}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <Form.Row>
                                          <Form.Input
                                            fieldName={`${name}.${fields.name.fieldName}`}
                                            fieldLabel="Name"
                                          />
                                          <Form.Input
                                            fieldName={`${name}.${fields.notes.fieldName}`}
                                            fieldLabel="Notes"
                                          />
                                          <div
                                            css={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              paddingTop: 6,
                                            }}
                                          >
                                            <Form.Button
                                              type="button"
                                              onClick={() =>
                                                records.remove(index)
                                              }
                                              tertiary
                                              className="clear"
                                              negative
                                            >
                                              <FontAwesomeIcon icon={faTrash} />
                                            </Form.Button>
                                          </div>
                                        </Form.Row>
                                        <Divider />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <div className="clearfix">
                        <Form.Button
                          type="button"
                          floated="right"
                          onClick={() => {
                            records.push({id: nextId++});
                          }}
                        >
                          Record
                        </Form.Button>
                      </div>
                    </>
                  )}
                </FieldArray>
              </Form.Section>
              <Form.TextArea fieldConfig={fields.notes} />
              <Json data={values} />
            </>
          )}
        />
      </Form.Container>
    </BasicPage>
  );
};
