import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useMemo, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {MeterReadingsService, MeterReadingSummaryDto} from '../api/generated';
import {BasicPage} from '../basic-page';
import {routes, buildPath} from '../routes';
import {
  PagedDataTableConfig,
  usePagedDataTable,
  renderViewButton,
  renderEditButton,
} from '../hooks/use-paged-data-table';
import {useOrganizationContext} from '../selectors';
import {AdvancedPagedRequest} from '../types';
import moment from 'moment';

type FetchParams = Parameters<
  typeof MeterReadingsService.getAllByOrganizationId
>['0'];

export const MeterReadingsDashboard = () => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const additionalParams = useMemo<FetchParams>(
    () => ({
      organizationId: organizationId,
    }),
    [organizationId]
  );

  const fetchMeterReadings = useCallback(
    (x: AdvancedPagedRequest<MeterReadingSummaryDto>) =>
      MeterReadingsService.getAllByOrganizationId({
        ...additionalParams,
        ...x,
      } as FetchParams),
    [additionalParams]
  );

  const pagedDataTable = usePagedDataTable(fetchMeterReadings, TableConfig, {});

  return <BasicPage title="Meter Readings">{pagedDataTable}</BasicPage>;
};

const TableConfig = PagedDataTableConfig(
  MeterReadingsService.getAllByOrganizationId,
  {
    columns: [
      {
        header: '',
        render: item => (
          <>
            {renderViewButton({
              item,
              descriptor: 'meter reading',
              route: buildPath(routes.accounts.dashboard, {
                id: item.accountId,
              }),
            })}
            {renderEditButton({
              item,
              descriptor: 'meter reading',
              route: buildPath(routes.meterReadings.detail, {
                id: item.id,
              }),
            })}
          </>
        ),
        cellProps: {
          collapsing: true,
        },
      },
      {
        header: 'Reading Date',
        sortable: 'readingDate',
        render: item => `${moment(item.readingDate).format('MM/DD/YYYY')}`,
      },
      {
        header: 'Account Number',
        column: 'accountNumber',
        sortable: 'accountNumber',
      },
      {
        header: 'Name',
        column: 'accountName',
        sortable: 'accountName',
      },
      {
        header: 'Reading Amount',
        column: 'readingAmount',
        sortable: 'readingAmount',
      },
      {
        header: 'Amount Due',
        column: 'amountDue',
        sortable: 'amountDue',
      },
    ],
    searchFieldNames: [
      'readingDate',
      'accountNumber',
      'accountName',
      'readingAmount',
      'amountDue',
    ],
    defaultSort: {
      column: 'readingDate',
      direction: 'DESC',
    },
  }
);
