import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useMemo, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {AccountsService, AccountSummaryDto} from '../api/generated';
import {BasicPage} from '../basic-page';
import {routes} from '../routes';
import {
  PagedDataTableConfig,
  usePagedDataTable,
  renderViewButton,
} from '../hooks/use-paged-data-table';
import {useOrganizationContext} from '../selectors';
import {AdvancedPagedRequest} from '../types';

type FetchParams = Parameters<
  typeof AccountsService.getAllByOrganizationId
>['0'];

export const AccountListing = () => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const additionalParams = useMemo<FetchParams>(
    () => ({
      organizationId: organizationId,
    }),
    [organizationId]
  );

  const fetchAccounts = useCallback(
    (x: AdvancedPagedRequest<AccountSummaryDto>) =>
      AccountsService.getAllByOrganizationId({
        ...additionalParams,
        ...x,
      } as FetchParams),
    [additionalParams]
  );

  const pagedDataTable = usePagedDataTable(fetchAccounts, TableConfig, {
    actions: (
      <Button as={Link} to={routes.accounts.create} primary>
        <FontAwesomeIcon icon={faPlus} /> New Account
      </Button>
    ),
  });

  return <BasicPage title="Accounts">{pagedDataTable}</BasicPage>;
};

const TableConfig = PagedDataTableConfig(AccountsService.getAll, {
  columns: [
    {
      header: '',
      render: item => (
        <>
          {renderViewButton({
            item,
            descriptor: 'account',
            route: routes.accounts.dashboard,
          })}
        </>
      ),
      cellProps: {
        collapsing: true,
      },
    },
    {
      header: 'Account Number',
      column: 'accountNumber',
      sortable: 'accountNumber',
    },
    {
      header: 'Name',
      column: 'name',
      sortable: 'name',
    },
    {
      header: 'Email',
      column: 'email',
      sortable: 'email',
    },
    {
      header: 'Phone Number',
      column: 'phoneNumber',
      sortable: 'phoneNumber',
    },
  ],
  searchFieldNames: [
    'accountNumber',
    'name',
    'email',
    'phoneNumber',
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'zipCode',
  ],
  defaultSort: {
    column: 'accountNumber',
    direction: 'DESC',
  },
});
