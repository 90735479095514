import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

type LocationNotifierProps = {
  onChange: (location: string) => void;
};

export const LocationNotifier = ({onChange}: LocationNotifierProps) => {
  const location = useLocation();

  useEffect(() => {
    if (location) {
      onChange(location.pathname);
    }
  }, [location, onChange]);

  return null;
};
