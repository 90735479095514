import React from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {MeterReadingsService} from '../api/generated';
import {States} from '../api/generated/enums';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DeleteButton} from '../components/confirm-delete-button';
import {Form} from '../forms';
import {useNotification} from '../hooks/use-notifications';
import {buildPath, routes} from '../routes';
import {useOrganizationContext} from '../selectors';

export const MeterReadingCreate = () => {
  const history = useHistory();
  const notifications = useNotification();
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;
  const match = useRouteMatch<{accountId: string}>();
  const accountId = Number(match.params.accountId);

  const accountDashboardUrl = buildPath(routes.accounts.dashboard, {
    id: accountId,
  });

  const breadcrumbs = [
    {title: 'Meter Readings', url: accountDashboardUrl},
    {title: 'Create'},
  ];

  const onSubmit = async values => {
    values.accountId = accountId;
    values.organizationId = organizationId;

    const response = await MeterReadingsService.create({body: values});
    if (response.hasErrors) {
      return response;
    }

    notifications.success('Meter Reading Created');
    history.goBack();
  };

  return (
    <BasicPage title={breadcrumbs}>
      <Form.Container>
        <Form
          onSubmit={onSubmit}
          render={() => (
            <>
              <Form.Section title="Meter Reading Information">
                <FormFields />
              </Form.Section>
              <div className="form-actions">
                <Form.Button type="submit" primary>
                  Create Meter Reading
                </Form.Button>
                <Form.Button
                  type="button"
                  secondary
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Form.Button>
              </div>
            </>
          )}
        />
      </Form.Container>
    </BasicPage>
  );
};

export const MeterReadingDetails = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const notifications = useNotification();

  const fetchMeterReading = useAsync(async () => {
    const {data} = await MeterReadingsService.getById({
      id,
    });
    return data;
  }, [id]);

  const meterReading = fetchMeterReading.value || undefined;

  //const userName = user ? `${user.firstName} ${user.lastName}` : '';

  const breadcrumbs = [{title: 'Meter Readings', url: routes.accounts.listing}];

  const onSubmit = async values => {
    const response = await MeterReadingsService.update({id, body: values});
    if (response.hasErrors) {
      return response;
    }
    notifications.success('Meter Reading Updated');
    history.goBack();
  };
  const [deleteMeterReadingState, deleteMeterReading] = useAsyncFn(async () => {
    const response = await MeterReadingsService.deleteById({id});
    if (response.hasErrors) {
      var errorMessage = response.errors.reduce((acc, item) => {
        return `${acc} \n ${item.errorMessage}`;
      }, '');

      notifications.error(errorMessage);
    } else {
      notifications.success('Meter Reading successfully deleted');
      history.push(routes.accounts.listing, {id: id});
    }
  });

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchMeterReading}>
        <Form.Container>
          <Form
            initialValues={meterReading}
            onSubmit={onSubmit}
            render={({values}) => (
              <>
                <Form.Section title="Meter Reading Information">
                  <FormFields />
                </Form.Section>
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Update Meter Reading
                  </Form.Button>
                  <Form.Button
                    type="button"
                    secondary
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Form.Button>

                  <DeleteButton
                    onConfirm={deleteMeterReading}
                    loading={deleteMeterReadingState.loading}
                    tertiary
                    icon={false}
                  />
                </div>
              </>
            )}
          />
        </Form.Container>
      </AsyncStateContainer>
    </BasicPage>
  );
};

const FormFields = () => {
  return (
    <>
      <Form.DatePicker fieldName="readingDate" fieldLabel="Reading Date" />
      <Form.Input
        type="number"
        fieldName="readingAmount"
        fieldLabel="Reading Amount"
      />
      <Form.TextArea fieldName="comments" fieldLabel="Comments" />
      <Form.InputCurrency fieldName="amountDue.value" fieldLabel="Amount Due" />
    </>
  );
};
