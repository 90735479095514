import {faPlus, faUnlock} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {Link} from 'react-router-dom';
import {useAsyncFn} from 'react-use';
import {Button, Segment, Checkbox} from 'semantic-ui-react';
import {UsersService} from '../api/generated';
import {BasicPage} from '../basic-page';
import {routes} from '../routes';
import {ConfirmButton} from '../components/confirm-button';
import {notifications} from '../utils/notification-service';
import {
  PagedDataTableConfig,
  usePagedDataTable,
  renderEditButton,
} from '../hooks/use-paged-data-table';
import {useProduce} from '../hooks/use-produce';
import {AllPermissions} from '../api/generated/enums';

type FilterState = {
  claims: string[];
};

export const UserListing = () => {
  const [filterState, setFilterState] = useProduce<FilterState>({
    claims: [],
  });
  const pagedDataTable = usePagedDataTable(UsersService.getAll, TableConfig, {
    actions: (
      <Button as={Link} to={routes.users.create} primary>
        <FontAwesomeIcon icon={faPlus} /> New User
      </Button>
    ),
    filter: {
      getParams: () => filterState,
      render: () => (
        <Segment secondary>
          <div>
            <strong>With Claims:</strong>
          </div>
          {AllPermissions.map(permission => (
            <div key={permission}>
              <Checkbox
                checked={filterState.claims.includes(permission)}
                label={permission}
                onChange={(e, {checked}) =>
                  setFilterState(state => {
                    if (checked) {
                      state.claims.push(permission);
                    } else {
                      state.claims = state.claims.filter(x => x !== permission);
                    }
                  })
                }
              />
            </div>
          ))}
        </Segment>
      ),
    },
  });

  return <BasicPage title="Users">{pagedDataTable}</BasicPage>;
};

const ResetPasswordButton = ({id}) => {
  const [sendResetState, sendReset] = useAsyncFn(async () => {
    const response = await UsersService.sendPasswordReset({
      id,
    });

    if (response.hasErrors) {
      notifications.error('Failed to send password reset link');
    } else {
      notifications.success('Password reset link sent!');
    }
  });

  return (
    <ConfirmButton
      onConfirm={sendReset}
      loading={sendResetState.loading}
      icon={faUnlock}
      loadingText="Sending..."
      tooltip="Send Password Reset"
    ></ConfirmButton>
  );
};

const TableConfig = PagedDataTableConfig(UsersService.getAll, {
  columns: [
    {
      header: '',
      render: item => (
        <>
          {renderEditButton({
            item,
            descriptor: 'User Account',
            route: routes.users.detail,
          })}
          <ResetPasswordButton id={item.id} />
        </>
      ),
      cellProps: {
        collapsing: true,
      },
    },
    {
      header: 'Email Address',
      column: 'emailAddress',
      sortable: 'emailAddress',
    },
    {
      header: 'Name',
      render: item => `${item.firstName} ${item.lastName}`,
      sortable: 'firstName',
    },
    {
      header: 'Role',
      column: 'role',
    },
  ],
  defaultSort: {
    column: 'emailAddress',
    direction: 'ASC',
  },
  searchFieldNames: ['firstName', 'lastName', 'emailAddress'],
});
