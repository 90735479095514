import css from '@emotion/css/macro';
import {faPencil, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useCallback, useMemo} from 'react';
import {useAsyncRetry} from 'react-use';
import {Button, Segment} from 'semantic-ui-react';
import {AsyncStateContainer} from '../components/async-state-container';
import {PageTitle} from '../components/page-title';
import {buildPath, routes} from '../routes';
import {AdvancedPagedRequest} from '../utils';
import {} from '../api/generated/enums';
import {
  AccountsService,
  MeterReadingsService,
  MeterReadingSummaryDto,
} from '../api/generated';
import {
  usePagedDataTable,
  PagedDataTableConfig,
  renderEditButton,
} from '../hooks/use-paged-data-table';
import {useRouteMatch, Link} from 'react-router-dom';
import {BasicPage} from '../basic-page';
import {DataList} from '../components/data-list';
import moment from 'moment';

type FetchParams = Parameters<
  typeof MeterReadingsService.getAllByAccountId
>['0'];

export const AccountDashboard = () => {
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);

  const fetchAccount = useAsyncRetry(async () => {
    const {data} = await AccountsService.getById({id});
    return data;
  }, [id]);

  const url = buildPath(routes.accounts.update, {
    id: id,
  });

  const createNewMeterReadingUrl = buildPath(routes.meterReadings.create, {
    accountId: id,
  });

  const additionalParams = useMemo<FetchParams>(() => {
    return {
      accountId: id,
    };
  }, [id]);

  const fetchMeterReadings = useCallback(
    (x: AdvancedPagedRequest<MeterReadingSummaryDto>) =>
      MeterReadingsService.getAllByAccountId({
        ...additionalParams,
        ...x,
      } as FetchParams),
    [additionalParams]
  );

  const pagedDataTable = usePagedDataTable(fetchMeterReadings, TableConfig, {
    actions: (
      <Button as={Link} to={createNewMeterReadingUrl} primary>
        <FontAwesomeIcon icon={faPlus} /> New Meter Reading
      </Button>
    ),
  });

  const account = fetchAccount.value;
  return (
    <AsyncStateContainer {...fetchAccount}>
      {account && (
        <BasicPage
          title={[
            {title: 'Accounts', url: routes.accounts.listing},
            {
              title: 'Account Details',
            },
          ]}
        >
          <Segment className="account-card" css={styles}>
            <div className="card-header" style={{marginBottom: '1.375rem'}}>
              <span style={{fontSize: '1.71428571em', fontWeight: 'bold'}}>
                {account.name}
              </span>

              <div className="edit-button">
                <Button
                  className="clear"
                  basic
                  icon
                  as={Link}
                  to={url}
                  aria-label={`Edit account`}
                >
                  <FontAwesomeIcon icon={faPencil} size="lg" />
                </Button>
              </div>
            </div>
            <DataList
              data={[
                {
                  label: 'Accounnt Number',
                  value: account.accountNumber,
                },
                {
                  label: 'Name',
                  value: account.name,
                },
                {
                  label: 'Email',
                  value: account.email,
                },
                {
                  label: 'PhoneNumber',
                  value: account.phoneNumber,
                },
                {
                  label: 'Address',
                  value: (
                    <>
                      <div>{account.addressLine1}</div>
                      {account.addressLine2 && (
                        <div>{account.addressLine2}</div>
                      )}
                      <div>
                        {account.city}, {account.state} {account.zipCode}
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </Segment>
          <br />
          <PageTitle title="Meter Readings" />
          <div>{pagedDataTable}</div>
        </BasicPage>
      )}
    </AsyncStateContainer>
  );
};

const TableConfig = PagedDataTableConfig(
  MeterReadingsService.getAllByAccountId,
  {
    columns: [
      {
        header: '',
        render: item => (
          <>
            {renderEditButton({
              item,
              descriptor: 'meter readings',
              route: routes.meterReadings.detail,
            })}
          </>
        ),
        cellProps: {
          collapsing: true,
        },
      },
      {
        header: 'Reading Date',
        sortable: 'readingDate',
        render: item => `${moment(item.readingDate).format('MM/DD/YYYY')}`,
      },
      {
        header: 'Reading Amount',
        column: 'readingAmount',
      },
      {
        header: 'Amount Due',
        column: 'amountDue',
      },
      {
        header: 'Comments',
        column: 'comments',
      },
    ],
    searchFieldNames: ['readingAmount', 'comments'],
    defaultSort: {
      column: 'readingDate',
      direction: 'DESC',
    },
  }
);

const styles = css`
  &.account-card {
    margin-bottom: 15px !important;

    .account-balance {
      align-self: flex-start;
      .ui.statistic {
        margin-top: 0;
        > .value {
          font-size: 2rem !important;
        }
      }
    }

    .card-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .edit-button {
        margin-left: auto;
      }
    }
  }
`;

const table = css``;
