import {reduce} from 'lodash';
import {StrictFormFieldProps} from 'semantic-ui-react';
import {CheckboxAdapterProps} from './adapters/checkbox';
import {DatePickerAdapterProps} from './adapters/datepicker';
import {DropdownAdapterProps} from './adapters/dropdown';
import {InputAdapterProps, InputMaskedAdapterProps} from './adapters/input';
import {RadioGroupAdapterProps} from './adapters/radio-group';
import {TextAreaAdapterProps} from './adapters/textarea';
import {KeysOfType} from '../types';

type Input<T> = {
  fieldLabel?: string;
  fieldClassName?: string;
  fieldHint?: React.ReactNode;
  fieldWidth?: StrictFormFieldProps['width'];
  inputProps?: T;
};

type Checkbox = Input<CheckboxAdapterProps>;
type Dropdown = Input<DropdownAdapterProps>;
type DatePicker = Input<DatePickerAdapterProps>;
type InputDefault = Input<InputAdapterProps>;
type InputMasked = Input<InputMaskedAdapterProps>;
type RadioGroup = Input<RadioGroupAdapterProps>;
type TextArea = Input<TextAreaAdapterProps>;

type FieldTypes =
  | Checkbox
  | DatePicker
  | Dropdown
  | InputDefault
  | InputMasked
  | RadioGroup
  | TextArea;

type FieldConfigs = {
  [fieldName: string]: FieldTypes;
};

export type FieldConfig = {
  fieldName: string;
} & FieldTypes;

export const currency = (config: InputDefault) => config;
export const checkbox = (config: Checkbox) => config;
export const datepicker = (config: DatePicker) => config;
export const decimal = (config: InputDefault) => config;
export const dropdown = (config: Dropdown) => config;
export const input = (config: InputDefault) => config;
export const masked = (config: InputMasked) => config;
export const radiogroup = (config: RadioGroup) => config;
export const textarea = (config: TextArea) => config;

export const fieldConfig = <T extends FieldConfigs>(config: T) => {
  const fields = reduce(
    config,
    (acc, value, key) => {
      acc[key] = {
        ...value,
        fieldName: key,
      } as FieldConfig;
      return acc;
    },
    {} as FieldConfigs
  );

  return fields as {[K in KeysOfType<T, any>]: FieldConfig};
};
