import ReachTooltip from '@reach/tooltip';
import React from 'react';
import css from '@emotion/css/macro';

type Tooltip = {
  label: string;
};

export const Tooltip: React.FC<Tooltip> = ({label, children}) => (
  <ReachTooltip label={label} css={styles}>
    <span>{children}</span>
  </ReachTooltip>
);

const styles = css`
  margin-top: 0.4rem;
`;
