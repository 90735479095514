import React from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {AccountsService} from '../api/generated';
import {States} from '../api/generated/enums';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DeleteButton} from '../components/confirm-delete-button';
import {Form} from '../forms';
import {useNotification} from '../hooks/use-notifications';
import {routes} from '../routes';
import {useOrganizationContext} from '../selectors';

export const AccountCreate = () => {
  const history = useHistory();
  const notifications = useNotification();
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const breadcrumbs = [
    {title: 'Accounts', url: routes.accounts.listing},
    {title: 'Create'},
  ];

  const onSubmit = async values => {
    values.organizationId = organizationId;

    const response = await AccountsService.create({body: values});
    if (response.hasErrors) {
      return response;
    }

    notifications.success('Account Created');
    history.push(routes.accounts.listing);
  };

  return (
    <BasicPage title={breadcrumbs}>
      <Form.Container>
        <Form
          onSubmit={onSubmit}
          render={() => (
            <>
              <Form.Section title="Account Information">
                <FormFields />
              </Form.Section>
              <div className="form-actions">
                <Form.Button type="submit" primary>
                  Create Account
                </Form.Button>
                <Form.Button secondary as={Link} to={routes.accounts.listing}>
                  Cancel
                </Form.Button>
              </div>
            </>
          )}
        />
      </Form.Container>
    </BasicPage>
  );
};

export const AccountUpdate = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const notifications = useNotification();

  const fetchAccount = useAsync(async () => {
    const {data} = await AccountsService.getById({
      id,
    });
    return data;
  }, [id]);

  const account = fetchAccount.value || undefined;

  //const userName = user ? `${user.firstName} ${user.lastName}` : '';

  const breadcrumbs = [
    {title: 'Accounts', url: routes.accounts.listing},
    {title: account ? account.name : ''},
  ];

  const onSubmit = async values => {
    const response = await AccountsService.update({id, body: values});
    if (response.hasErrors) {
      return response;
    }
    notifications.success('Account Updated');
    history.goBack();
  };

  const [deleteAccountState, deleteAccount] = useAsyncFn(async () => {
    const response = await AccountsService.deleteById({id});
    if (response.hasErrors) {
      var errorMessage = response.errors.reduce((acc, item) => {
        return `${acc} \n ${item.errorMessage}`;
      }, '');

      notifications.error(errorMessage);
    } else {
      notifications.success('Account successfully deleted');
      history.push(routes.accounts.listing, {id: id});
    }
  });

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchAccount}>
        <Form.Container>
          <Form
            initialValues={account}
            onSubmit={onSubmit}
            render={({values}) => (
              <>
                <Form.Section title="Account Information">
                  <FormFields account="account" />
                </Form.Section>
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Update Account
                  </Form.Button>
                  <Form.Button secondary as={Link} to={routes.accounts.listing}>
                    Cancel
                  </Form.Button>

                  <DeleteButton
                    onConfirm={deleteAccount}
                    loading={deleteAccountState.loading}
                    tertiary
                    icon={false}
                  />
                </div>
              </>
            )}
          />
        </Form.Container>
      </AsyncStateContainer>
    </BasicPage>
  );
};

const FormFields = account => {
  return (
    <>
      <Form.Input fieldName="accountNumber" fieldLabel="Account Number" />
      <Form.Input fieldName="name" fieldLabel="Name" />
      <Form.Input fieldName="email" fieldLabel="Email" />
      <Form.InputMasked
        fieldName="phoneNumber"
        fieldLabel="Phone Number"
        placeholder="XXX-XXX-XXXX"
        options={{
          phone: true,
          phoneRegionCode: 'US',
          delimiter: '-',
        }}
      />

      <Form.Section title="Address Information">
        <Form.Input fieldName="addressLine1" fieldLabel="Address Line 1" />
        <Form.Input fieldName="addressLine2" fieldLabel="Address Line 2" />
        <Form.Row>
          <Form.Input fieldName="city" fieldLabel="City" />
          <Form.Dropdown
            fieldName="state"
            fieldLabel="State"
            enum={States}
            selection
            search
          />
          <Form.Input fieldName="zipCode" fieldLabel="Zip Code" />

          <Form.CustomValue fieldName="_" render={() => false} />
          <Form.CustomValue fieldName="_" render={() => false} />
          <Form.CustomValue fieldName="_" render={() => false} />
        </Form.Row>
      </Form.Section>
    </>
  );
};
