import * as Sentry from '@sentry/browser';
import {Env} from '../config/env-vars';

type GuardedResult<ReturnType, DefaultValueType = ReturnType> =
  | ReturnType
  | DefaultValueType;

type GuardedFn<ReturnType, DefaultValueType = ReturnType> = (
  ...args
) => GuardedResult<ReturnType, DefaultValueType>;

function guard<InputParam, ReturnType, DefaultValue = ReturnType>(
  fn: (param1: InputParam) => ReturnType | DefaultValue,
  defaultValue: ReturnType
): (param1: InputParam) => ReturnType | DefaultValue;
function guard<InputParam1, InputParam2, ReturnType, DefaultValue = ReturnType>(
  fn: (param1: InputParam1, param2: InputParam2) => ReturnType | DefaultValue,
  defaultValue: ReturnType
): (param1: InputParam1, param2: InputParam2) => ReturnType | DefaultValue;
function guard<
  InputParam1,
  InputParam2,
  InputParam3,
  ReturnType,
  DefaultValue = ReturnType
>(
  fn: (
    param1: InputParam1,
    param2: InputParam2,
    param3: InputParam3
  ) => ReturnType | DefaultValue,
  defaultValue: ReturnType
): (
  param1: InputParam1,
  param2: InputParam2,
  param3: InputParam3
) => ReturnType | DefaultValue {
  const guarded: typeof fn = (...args) => {
    try {
      return fn(...args);
    } catch (error) {
      return handleError(error, defaultValue);
    }
  };

  return guarded;
}

function guardAsync<InputParam, ReturnType, DefaultValue = ReturnType>(
  fn: (param1: InputParam) => Promise<ReturnType | DefaultValue>,
  defaultValue: ReturnType
): (param1: InputParam) => Promise<ReturnType | DefaultValue>;
function guardAsync<
  InputParam1,
  InputParam2,
  ReturnType,
  DefaultValue = ReturnType
>(
  fn: (
    param1: InputParam1,
    param2: InputParam2
  ) => Promise<ReturnType | DefaultValue>,
  defaultValue: ReturnType
): (
  param1: InputParam1,
  param2: InputParam2
) => Promise<ReturnType | DefaultValue>;
function guardAsync<
  InputParam1,
  InputParam2,
  InputParam3,
  ReturnType,
  DefaultValue = ReturnType
>(
  fn: (
    param1: InputParam1,
    param2: InputParam2,
    param3: InputParam3
  ) => Promise<ReturnType | DefaultValue>,
  defaultValue: ReturnType
): (
  param1: InputParam1,
  param2: InputParam2,
  param3: InputParam3
) => Promise<ReturnType | DefaultValue> {
  const guarded: typeof fn = async (...args) => {
    try {
      return await fn(...args);
    } catch (error) {
      return handleError(error, defaultValue);
    }
  };

  return guarded;
}

function handleError<T>(error: Error, defaultValue: T): T {
  if (Env.isProductionBuild) {
    Sentry.captureException(error);
  }
  return defaultValue;
}

export {guard, guardAsync};
