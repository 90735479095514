import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'typeface-roboto';
import 'semantic-ui-less/semantic.less';
import '@reach/skip-nav/styles.css';
import '@reach/tooltip/styles.css';

import './dev-tools';
import './analytics/sentry';
import './api/generated/config';

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import {App} from './app';
import {AuthProvider} from './auth/use-auth';
import {Env} from './config/env-vars';
import {GlobalStyles} from './styles';

ReactDOM.render(
  <AuthProvider>
    <Router basename={Env.subdirectory}>
      <GlobalStyles />
      <App />
    </Router>
  </AuthProvider>,
  document.getElementById('root')
);
