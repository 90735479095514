import React, {useCallback} from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync} from 'react-use';
import {SettingsService} from '../api/generated';
import * as Enums from '../api/generated/enums';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {Form} from '../forms';
import {useNotification} from '../hooks/use-notifications';
import {routes} from '../routes';

export const SettingDetails = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);

  const notifications = useNotification();

  const fetchSetting = useAsync(async () => {
    const {data} = await SettingsService.getById({id});
    return data;
  }, [id]);

  const onSubmit = useCallback(
    async values => {
      const response = await SettingsService.save({
        id,
        body: values,
      });

      if (response.hasErrors) {
        return response;
      }

      notifications.success('Setting updated');
      history.push(routes.settings.listing);
    },
    [history, id, notifications]
  );

  return (
    <AsyncStateContainer {...fetchSetting}>
      {fetchSetting.value && (
        <BasicPage
          title={[
            {title: 'Settings', url: routes.settings.listing},
            {title: fetchSetting.value.key},
          ]}
        >
          <Form.Container>
            <Form
              initialValues={fetchSetting.value}
              onSubmit={onSubmit}
              render={() => (
                <>
                  <Form.Input fieldName="key" fieldLabel="Key" readOnly />
                  {fetchSetting.value?.enum ? (
                    <Form.Dropdown
                      fieldName="value"
                      fieldLabel="Value"
                      selection
                      enum={Enums[fetchSetting.value.enum]}
                    />
                  ) : (
                    <Form.Input fieldName="value" fieldLabel="Value" />
                  )}
                  <div className="form-actions">
                    <Form.Button type="submit" primary>
                      Update Setting
                    </Form.Button>
                    <Form.Button
                      secondary
                      as={Link}
                      to={routes.settings.listing}
                    >
                      Cancel
                    </Form.Button>
                  </div>
                </>
              )}
            />
          </Form.Container>
        </BasicPage>
      )}
    </AsyncStateContainer>
  );
};
