import React, {useContext} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {AuthContext} from '../auth/use-auth';
import {FormsExample} from '../forms-example';
import {SettingDetails} from '../settings/setting-details';
import {SettingListing} from '../settings/setting-listing';
import {UserCreate, UserDetails} from '../users/user-create-update';
import {UserListing} from '../users/user-listing';
import {OrganizationCreate, OrganizationDetails} from '../organizations/organization-create-update';
import {OrganizationListing} from '../organizations/organization-listing';
import {AccountCreate, AccountUpdate} from '../accounts/account-create-update';
import {AccountDashboard} from '../accounts/account-dashboard';
import {AccountListing} from '../accounts/account-listing';
import {MeterReadingCreate, MeterReadingDetails} from '../meter-readings/meter-reading-create-update';
import {MeterReadingsDashboard} from '../meter-readings/meter-readings-dashboard';
import {WorkflowEditor} from '../workflow/editor';
import { RateTableTierListing } from '../rate-table-tiers/rate-table-tier-listing';
import {RateTableTierCreate, RateTableTierDetails} from '../rate-table-tiers/rate-table-tier-create-update';

type pathParams = {
  [key: string]: string | number;
};

export const routes = {
  dashboard: '/',
  callback: '/callback',
  users: {
    create: `/users/create`,
    detail: `/users/:id`,
    listing: `/users`,
  },
  organizations: {
    create: `/organizations/create`,
    detail: `/organizations/:id`,
    listing: `/organizations`,
  },
  accounts: {
    create: `/accounts/create`,
    dashboard: `/accounts/dashboard/:id`,
    update: `/accounts/update/:id`,
    listing: `/accounts`,
  },
  rateTableTiers: {
    create: `/rate-table-tiers/create`,
    detail: `/rate-table-tiers/:id`,
    listing: `/rate-table-tiers`,
  },
  meterReadings: {
    create: `/acounts/:accountId/meter-readings/create`,
    dashboard: `/meter-readings-dashboard`,
    detail: `/meter-readings/:id`,
    listing: `/meter-readings`,
  },
  settings: {
    detail: `/settings/:id`,
    listing: `/settings`,
  },
  forms: '/forms',
};

export const Routes = () => {
  return (
    <Switch>
      <Route path={routes.dashboard} exact>
        <MeterReadingsDashboard />
      </Route>
      <Route path={routes.users.listing} exact>
        <UserListing />
      </Route>
      <Route path={routes.users.create} exact>
        <UserCreate />
      </Route>
      <Route path={routes.users.detail}>
        <UserDetails />
      </Route>
      <Route path={routes.organizations.listing} exact>
        <OrganizationListing />
      </Route>
      <Route path={routes.organizations.create} exact>
        <OrganizationCreate />
      </Route>
      <Route path={routes.organizations.detail} exact>
        <OrganizationDetails />
      </Route>
      <Route path={routes.accounts.listing} exact>
        <AccountListing />
      </Route>
      <Route path={routes.accounts.create} exact>
        <AccountCreate />
      </Route>
      <Route path={routes.accounts.update} exact>
        <AccountUpdate />
      </Route>
      <Route path={routes.accounts.dashboard} exact>
        <AccountDashboard />
      </Route>
      <Route path={routes.rateTableTiers.listing} exact>
        <RateTableTierListing />
      </Route>
      <Route path={routes.rateTableTiers.create} exact>
        <RateTableTierCreate />
      </Route>
      <Route path={routes.rateTableTiers.detail} exact>
        <RateTableTierDetails />
      </Route>
      {/* <Route path={routes.meterReadings.listing} exact>
        <RateTableTierListing />
      </Route> */}
      <Route path={routes.meterReadings.create} exact>
        <MeterReadingCreate />
      </Route>
      <Route path={routes.meterReadings.detail} exact>
        <MeterReadingDetails />
      </Route>
      <Route path={routes.meterReadings.dashboard} exact>
        <MeterReadingsDashboard />
      </Route>
      <Route path={routes.settings.listing} exact>
        <SettingListing />
      </Route>
      <Route path={routes.settings.detail}>
        <SettingDetails />
      </Route>
      <Route path={routes.forms}>
        <FormsExample />
      </Route>
      <Route path={routes.callback}>
        <Callback />
      </Route>
      <Route>
        <Redirect to={routes.dashboard} />
      </Route>
    </Switch>
  );
};

const Callback = () => {
  const context = useContext(AuthContext);
  const redirect = context.redirectUrl ?? routes.dashboard;

  return <Redirect to={redirect} />;
};

export const buildPath = (path: string, params: pathParams) => {
  return Object.keys(params).reduce((url, key) => {
    return url.replace(`:${key}`, `${params[key]}`);
  }, path);
};
