import {getUser, mapOidcUser, userManager} from '.';
import produce from 'immer';
import React, {createContext, useContext, useEffect, useReducer} from 'react';
import {Dimmer, Loader} from 'semantic-ui-react';
import {DelayedLoadingContainer} from '../components/delayed-loading-container';
import {Env} from '../config/env-vars';
import {LiteralKeyedObject, User} from '../types';
import {logger} from '../utils/logger';

const log = logger('auth');

type AuthState = {
  pending: boolean;
  user: User | null;
  error: Error | null;
  redirectUrl?: string;
};

type Actions = 'ON_USER_LOADED' | 'ON_USER_UNLOADED' | 'REDIRECTING';

type Handler = (state: AuthState, payload: any) => void;
type Handlers = LiteralKeyedObject<Actions, Handler>;

type Action = {
  type: Actions;
  payload?: any;
};

const handlers: Handlers = {
  ON_USER_LOADED: (state, {user}) => {
    state.pending = false;
    state.user = mapOidcUser(user);

    const {url} = user.state || {};
    if (url) {
      state.redirectUrl = url;
    }
  },
  ON_USER_UNLOADED: state => {
    state.pending = true;
    state.user = null;
  },
  REDIRECTING: state => {
    state.pending = true;
    state.user = null;
  },
};

const INITIAL_STATE: AuthState = {
  user: null,
  pending: true,
  error: null,
  redirectUrl: undefined,
};

const reducer = (state: AuthState, {type, payload}: Action): AuthState => {
  log.debug('Auth Reducer', {type, payload});
  const handler = handlers[type] || (() => state);
  return produce(state, draft => {
    handler(draft, payload);
  });
};

export const AuthContext = createContext<AuthState>(INITIAL_STATE);

export const AuthProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    (async () => {
      const user = await getUser();
      log.debug('Initial user', user);

      userManager.events.addUserLoaded(async user => {
        dispatch({type: 'ON_USER_LOADED', payload: {user}});
      });

      userManager.events.addUserUnloaded(() => {
        dispatch({type: 'ON_USER_UNLOADED', payload: {}});
      });

      if (user) {
        dispatch({type: 'ON_USER_LOADED', payload: {user}});
      } else if (window.location.href.includes('#id_token')) {
        log.debug('Handle callback');
        try {
          await userManager.signinRedirectCallback();
        } catch (error) {
          log.error('Callback Error', error);
        }
      } else {
        log.error('signinRedirect');
        dispatch({type: 'REDIRECTING', payload: {}});

        let pathname = window.location.pathname;
        if (Env.subdirectory) {
          pathname = pathname.replace(Env.subdirectory, '');
        }

        userManager.signinRedirect({
          state: {
            url: pathname,
          },
        });
      }
    })();
  }, []);

  if (state.pending) {
    return (
      <DelayedLoadingContainer delayInMs={1000}>
        <Dimmer active inverted>
          <Loader indeterminate />
        </Dimmer>
      </DelayedLoadingContainer>
    );
  }

  if (!state.user && !state.pending) {
    return <div>Not Authenticated</div>;
  }

  return <AuthContext.Provider value={state} {...props} />;
};

export function useUser(): User {
  const {user} = useContext(AuthContext);
  if (!user) {
    throw new Error(`useUser must be used within an authenticated app`);
  }
  return user;
}
