import React from 'react';
import {Table} from 'semantic-ui-react';
import css from '@emotion/css/macro';

type DataList = {
  data: {label: React.ReactNode; value: React.ReactNode}[];
};
export const DataList: React.FC<DataList> = ({data}) => {
  return (
    <Table basic="very" compact unstackable css={datumStyles}>
      <Table.Body>
        {data.map(({label, value}, i) => (
          <Table.Row key={i} verticalAlign="top">
            <Table.Cell collapsing className="datum-label">
              {label}:
            </Table.Cell>
            <Table.Cell className="datum-value">{value}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const datumStyles = css`
  .datum-label {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.9rem !important;
    line-height: 1.4em;
  }

  .datum-value {
    font-weight: 500;
    font-size: 0.971429rem;
    line-height: 1.28571429em;
    text-transform: none;
    color: #0a2233;
  }
`;
