import {faUpload} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import 'cleave.js/dist/addons/cleave-phone.us';
import {CleaveOptions} from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import {cx} from 'emotion';
import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {
  Button,
  HtmlInputrops,
  Input,
  StrictInputProps,
} from 'semantic-ui-react';
import {AnyObject} from '../../types';

import {
  defaultValueConverter,
  ValueConverter,
  currencyValueConverter,
  decimalValueConverter,
} from '../value-converters';

type InputAdditionalProps = {
  valueConverter?: ValueConverter;
};

export type InputAdapterProps = StrictInputProps &
  HtmlInputrops &
  InputAdditionalProps;

export const InputAdapter = ({input, meta, ...rest}) => {
  let {valueConverter, ...additionalProps} = rest as InputAdditionalProps;
  const converter = (valueConverter || defaultValueConverter) as ValueConverter;

  const [value, setValue] = useState(converter.format(input.value));

  // if formatting the external value does not match
  // the internal value, update internal value
  useEffect(() => {
    const shouldSetValue = !converter.equals(input.value, value);
    if (shouldSetValue) {
      setValue(converter.format(input.value));
    }
  }, [converter, input.value, value]);

  return (
    <Input
      {...input}
      value={value}
      {...additionalProps}
      onChange={(event, {value}) => {
        setValue(value);
        input.onChange(converter.parseInput(value));
      }}
      onFocus={event => {
        input.onFocus(event);
        event.target.select();
      }}
      onBlur={event => {
        setValue(converter.format(input.value));
        input.onBlur(event);
      }}
    />
  );
};

export const InputCurrencyAdapter = ({input, meta, ...rest}) => {
  const props: AnyObject = {...rest, valueConverter: currencyValueConverter};

  return (
    <InputAdapter
      input={input}
      meta={meta}
      icon="dollar"
      iconPosition="left"
      {...props}
      className={cx('input-currency', props.className)}
    />
  );
};

export const InputFileUrlAdapter = ({input, meta, ...rest}) => {
  const {onDrop, ...props} = rest;
  const [uploading, setUploading] = useState(false);
  const {getRootProps, getInputProps} = useDropzone({
    onDrop: async acceptedFiles => {
      setUploading(true);
      const uri = await onDrop(acceptedFiles[0]);
      input.onChange(uri);
      setUploading(false);
    },
  });

  return (
    <InputAdapter
      input={input}
      meta={meta}
      action={
        <div {...getRootProps({className: 'ui button file-upload'})}>
          <input {...getInputProps()} />
          <Button
            basic
            icon
            className="action-button"
            type="button"
            loading={uploading}
          >
            <FontAwesomeIcon icon={faUpload} />
          </Button>
        </div>
      }
      {...props}
      className={cx('input-file-upload', props.className)}
    />
  );
};

export const InputDecimalAdapter = ({input, meta, ...rest}) => {
  const props = {...rest, valueConverter: decimalValueConverter};
  return (
    <InputAdapter
      input={input}
      meta={meta}
      icon="percent"
      iconPosition="right"
      {...props}
    />
  );
};

export type InputMaskedAdapterProps = InputAdapterProps & {
  options?: CleaveOptions;
};

export const InputMaskedAdapter = ({input, meta, ...rest}) => {
  const {className, ...inputProps} = rest;
  return (
    <div className={cx('ui input', className)}>
      <Cleave {...input} {...inputProps} />
    </div>
  );
};
