import {Global} from '@emotion/core';
import css from '@emotion/css/macro';
import {darken, lighten, textInputs, transparentize} from 'polished';
import React from 'react';
import {Media} from './breakpoints';

const colors = {
  white1: '#FFFFFF',
  white2: '#F5F5F5',
  grey1: '#979C9E',
  grey2: '#CBCFD1',
  black1: '#1B1C1D',
  black2: '#444',
  transparent: 'rgba(0,0,0,0)',
};

export const themeColors = {
  ...colors,
  basicBoxShadow: 'rgba(34, 36, 38, 0.15)',
  textLabel: colors.black2,
  transparentHover: '#efefef',
  warning: '#712928',
  warningBackground: '#fff6f6',
  warningBoxShadow: '#9F3A38',
  errorText: '#9F3A38',
  mutedText: colors.grey1,
  warningText: '#7b341e',
};

const globalStyles = css`
  .root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .ui {
    &.form {
      ${textInputs()} {
        border-width: 2px;
        border-color: ${themeColors.grey2};

        &:focus {
          outline: -webkit-focus-ring-color auto 3px;
        }

        &[readonly] {
          background: #f1f1f1;
          color: #6b6b6b;
          &:focus {
            outline: none;
            background: #f1f1f1;
            color: #6b6b6b;
          }
        }
      }

      .field.error {
        ${textInputs()} {
          background: #fff;
          border-color: #e0b4b4;
          color: #9f3a38;
          box-shadow: none;
        }
      }

      .dropdown,
      .selection.dropdown {
        border-width: 2px;
        border-color: ${themeColors.grey2};

        .menu {
          border-width: 2px;
          border-color: ${themeColors.grey2};
          margin: 0px -2px;
          min-width: calc(100% + 4px);
          width: calc(100% + 4px);
        }

        &:not(.button) > .default.text,
        &.default:not(.button) > .text {
          color: ${themeColors.grey1};
        }
      }

      .success.message,
      .warning.message,
      .error.message {
        display: block;
      }

      .error.error input:-webkit-autofill {
        box-shadow: inherit !important;
        border-color: #cbcfd1 !important;
      }
    }

    &.input {
      &.labeled:not([class*='corner labeled']) .label {
        line-height: 1.3rem;
        border-width: 2px;
        border-color: ${themeColors.grey2};
        padding-top: calc(0.78571429em - 2px);
        padding-bottom: calc(0.78571429em - 2px);
        background-color: #fff;

        &:first-of-type + input {
          border-left: 0;
        }
      }

      &.action {
        > .button {
          border: solid 2px ${themeColors.grey2} !important;
          box-shadow: none;

          &:hover {
            box-shadow: none;
          }
        }

        &:not([class*='left action']) {
          > input {
            border-right-color: ${themeColors.grey2} !important;
          }

          > .button:last-child {
            border-left: none !important;
          }
        }
      }

      &.input-file-upload {
        .file-upload {
          padding: 0 !important;
          background: #fff;

          .ui.button {
            margin: 0;
            border-radius: 0;
          }
        }
      }
    }

    &.table {
      border-top: 0.2em solid ${themeColors.black1};
      font-size: 1rem;

      &[class*='very basic']:not(.sortable):not(.striped) td:first-of-type {
        padding-left: 0.5em;
      }

      .error-text {
        color: ${themeColors.errorText} !important;
      }

      .muted-text {
        color: ${themeColors.mutedText} !important;
      }

      .warning-text {
        color: ${themeColors.warningText} !important;
      }

      &.compact th {
        padding: 0.528571em 0.78571429em;
      }

      &.table tr th {
        border-left: none;
      }

      &.celled tr th {
        border-left: 1px solid rgba(222, 222, 222, 0.43);
      }

      thead th {
        border-radius: 0 !important;
        font-weight: 500;
      }

      &.selectable tbody tr {
        cursor: pointer;

        &.active,
        &.active:hover,
        &:hover {
          background: #d3eafb !important;

          .ui.label {
            /* border: solid 1px; */
          }
        }
      }

      tbody tr,
      thead tr {
        .hidden-row-action {
          opacity: 0;
        }

        &:hover .hidden-row-action {
          opacity: 1;
        }

        td:first-of-type,
        th:first-of-type {
          padding-left: 30px;
        }

        td:last-child,
        th:last-child {
          padding-right: 30px;
        }
      }
    }

    &.button {
      font-weight: normal;

      &.primary {
        background-color: ${themeColors.black1};

        &:hover,
        &:focus {
          background-color: ${lighten(0.075, themeColors.black1)} !important;
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, themeColors.basicBoxShadow)};
          outline: -webkit-focus-ring-color auto 1px;
        }
      }

      &.secondary {
        background-color: #e8e8e8;
        color: ${themeColors.textLabel};
        font-weight: normal;
        border-radius: 0.28571429rem;
        text-transform: none;
        text-shadow: none !important;
        &:hover,
        &:focus {
          color: ${themeColors.textLabel};
          background-color: ${darken(0.075, '#e8e8e8')} !important;

          &.active {
            color: ${themeColors.white1};
            background-color: ${themeColors.black1} !important;
          }
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, themeColors.basicBoxShadow)};
          outline: -webkit-focus-ring-color auto 1px;
        }
      }

      &.tertiary {
        background-color: #fff;
        color: ${themeColors.textLabel};
        font-weight: normal;
        border-radius: 0.28571429rem;
        text-transform: none;
        text-shadow: none !important;

        &.negative {
          color: #db2828;

          &:hover,
          &:focus {
            background: #f9dbdc !important;
            color: #db2828;
          }
        }

        &:hover,
        &:focus {
          color: ${themeColors.textLabel};
          background-color: ${darken(0.075, '#e8e8e8')} !important;
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, themeColors.basicBoxShadow)};
          outline: -webkit-focus-ring-color auto 1px;
        }
      }

      &.basic {
        background-color: ${themeColors.white1} !important;
        color: ${themeColors.black1};

        &:not(.action-button) {
          box-shadow: 0px 0px 0px 1px ${themeColors.basicBoxShadow} !important;
        }
        &:hover {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, themeColors.basicBoxShadow)};
          color: ${darken(0.04, themeColors.black1)} !important;
          background-color: ${darken(0.08, themeColors.white1)} !important;
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, themeColors.basicBoxShadow)};
          color: ${darken(0.04, themeColors.black1)} !important;
          background-color: ${darken(0.02, themeColors.white1)} !important;
        }
        &:active {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.08, themeColors.basicBoxShadow)};
          color: ${darken(0.08, themeColors.black1)} !important;
          background-color: ${darken(0.05, themeColors.white1)} !important;
        }

        &.transparent {
          background-color: ${themeColors.transparent} !important;
          &:hover {
            background-color: ${themeColors.transparentHover} !important;
          }
          &:active {
            background-color: ${darken(
              0.05,
              themeColors.transparentHover
            )} !important;
          }
        }

        &.warning {
          background-color: ${themeColors.warningBackground} !important;
          box-shadow: 0px 0px 0px 1px ${themeColors.warningBoxShadow} !important;
          color: ${themeColors.warning};
          &:hover {
            box-shadow: 0px 0px 0px 1px
              ${darken(0.2, themeColors.warningBoxShadow)};
            color: ${darken(0.2, themeColors.warning)} !important;
            background-color: ${darken(
              0.03,
              themeColors.warningBackground
            )} !important;
          }
          &:focus {
            box-shadow: 0px 0px 0px 1px
              ${darken(0.2, themeColors.warningBoxShadow)};
            color: ${darken(0.2, themeColors.warning)} !important;
            background-color: ${darken(
              0.03,
              themeColors.warningBackground
            )} !important;
          }
          &:active {
            box-shadow: 0px 0px 0px 1px
              ${darken(0.3, themeColors.warningBoxShadow)};
            color: ${darken(0.3, themeColors.warning)} !important;
            background-color: ${darken(
              0.05,
              themeColors.warningBackground
            )} !important;
          }
        }

        &.clear {
          background-color: transparent !important;
          box-shadow: none !important;

          &:hover,
          &:focus {
            background-color: rgba(27, 28, 29, 0.1) !important;
          }
        }
      }

      &.link {
        background: transparent none;
        color: rgba(0, 0, 0, 0.6);
        font-weight: normal;
        border-radius: 0.28571429rem;
        text-transform: none;
        text-shadow: none !important;
        display: inline-block;
        padding: 5px 0px;
        &:hover {
          color: rgba(0, 0, 0, 0.8);
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, themeColors.basicBoxShadow)};
          color: ${darken(0.04, themeColors.black1)} !important;
          background-color: ${darken(0.02, themeColors.white1)} !important;
          outline: -webkit-focus-ring-color auto 1px;
        }
        &:active {
          background: #f8f8f8;
        }
      }

      &.white {
        background: #fff !important;
        box-shadow: 0px 0px 0px 1px rgba(107, 112, 117, 0.35) inset,
          0px 0px 0px 0px rgba(50, 53, 56, 0.15) inset;
      }

      &.clear {
        background: transparent none;
        color: rgba(0, 0, 0, 0.6);
        font-weight: normal;
        border-radius: 0.28571429rem;
        text-transform: none;
        text-shadow: none !important;
        &:hover {
          color: rgba(0, 0, 0, 0.8);
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, themeColors.basicBoxShadow)};
          color: ${darken(0.04, themeColors.black1)} !important;
          background-color: ${transparentize(
            0.9,
            themeColors.black1
          )} !important;
          outline: -webkit-focus-ring-color auto 1px;
        }
        &:active {
          background: #f8f8f8;
        }
      }

      &.no-focus:not(:active) {
        box-shadow: none !important;
        outline: none !important;
      }
    }

    &.header {
      &.no-margin {
        margin: 0;
      }

      &.inverted.attached {
        background-color: ${themeColors.black1};
      }

      &.icon svg {
        display: block;
        line-height: 1;
        padding: 0em;
        font-size: 3em;
      }

      &.icon > svg {
        margin: 0em auto 0.5rem;
      }
    }

    &.buttons > .ui.button:not(.basic):not(.inverted),
    &.buttons:not(.basic):not(.inverted) > .button,
    &.buttons .ui.button:not(.basic):not(.inverted),
    &.buttons:not(.basic):not(.inverted) .button {
      box-shadow: 0px 0px 0px 1px transparent inset,
        0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
    }

    &.menu {
      &.pagination {
        border: 1px solid rgba(175, 176, 177, 0.45);
        box-shadow: none;

        .item:before {
          background: rgba(175, 176, 177, 0.45);
        }
      }

      &.secondary.pointing .active.item {
        font-weight: normal;
        color: ${themeColors.black1};
        border-color: ${themeColors.black1};
      }
    }

    .modal {
      .content .actions {
        margin: 15px -1.5rem -1.5rem;
        padding: 15px 10px;
        border-top: solid 1px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.02);
      }

      & > .actions {
        text-align: left;
      }
    }

    &.segment {
      padding: 2em;
      display: block;

      ${Media('TabletMax')} {
        padding: 1em;
      }

      &.placeholder {
        min-height: 8rem;
        display: flex;
      }
    }

    a.hint {
      float: right;
      font-weight: normal;
      font-size: 1rem;
    }
  }

  .muted {
    color: ${themeColors.mutedText} !important;
  }

  [data-reach-skip-link]:focus {
    z-index: 10;
  }

  [data-reach-tab-list] {
    margin: 0 0 1em 0;
    display: inline-block;
    background: #fff;
    border-radius: 5px;
    padding: 4px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  }

  [data-reach-tab-panel] {
    outline: none;
  }

  [data-reach-tab] {
    display: inline-block;
    padding: 0.25em 0.5em;
    margin: 0;
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    align-self: flex-end;
    border: none;
    margin: 0 2.5px 0;
    padding: 0.557143em 1.14285714em;
    border-bottom-width: 2px;
    transition: color 0.1s ease;
    background-color: transparent;
    color: #0a2233;
    border-radius: 5px;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    &[data-selected] {
      background: #185885;
      color: #fff;
    }

    &:first-of-type {
      margin-left: 0px;
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }

  [data-reach-tab]:disabled {
    opacity: 0.25;
    cursor: default;
  }
`;

export const GlobalStyles = () => <Global styles={globalStyles} />;
