/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export class IList<T> extends Array<T> {}
export class List<T> extends Array<T> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount: number;
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount!: number;
}

// customer definition
export interface Response<T> {
  data: T | null;
  hasErrors: boolean;
  errors: Error[];
}

export interface Error {
  propertyName: string;
  errorMessage: string;
}

export interface AdvancedQueryPage<T> {
  items: T[];
  page: number;
  pageSize: number;
  pageCount: number;
  totalItemCount: number;
}

export class AccountsService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<AccountSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/accounts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateAccountRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/accounts';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByOrganizationId(
    params: {
      /**  */
      organizationId?: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<AccountSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/accounts/by-organization';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        OrganizationId: params['organizationId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/accounts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateAccountRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/accounts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/accounts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CompileTimeStaticsService {
  /**
   *
   */
  static getAll(options: IRequestOptions = {}): Promise<Response<string>> {
    return new Promise((resolve, reject) => {
      let url = '/api/compile-time-statics';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FilesService {
  /**
   *
   */
  static upload(
    params: {
      /**  */
      file?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UploadFileResponse>> {
    return new Promise((resolve, reject) => {
      let url = '/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null as any;
      data = new FormData();
      if (params['file']) {
        data.append('file', params['file'] as any);
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MeterReadingsService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<MeterReadingSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateMeterReadingRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<MeterReadingDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByAccountId(
    params: {
      /**  */
      accountId?: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<MeterReadingSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings/by-account';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        AccountId: params['accountId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByOrganizationId(
    params: {
      /**  */
      organizationId?: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<MeterReadingSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings/by-organization';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        OrganizationId: params['organizationId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<MeterReadingDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateMeterReadingRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<MeterReadingDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class OrganizationsService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<OrganizationSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/organizations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateOrganizationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<OrganizationDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/organizations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<OrganizationDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/organizations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateOrganizationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<OrganizationDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/organizations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/organizations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getContextById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<OrganizationContextDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/organizations/{id}/context';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RateTablesService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<RateTableSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-tables';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateRateTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RateTableDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-tables';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RateTableDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-tables/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateRateTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RateTableDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-tables/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-tables/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RateTableTiersService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<RateTableTierSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-table-tiers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateRateTableTierRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RateTableTierDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-table-tiers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByOrganization(
    params: {
      /**  */
      organizationId?: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<RateTableTierSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-table-tiers/by-organization';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        OrganizationId: params['organizationId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RateTableTierDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-table-tiers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateRateTableTierRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RateTableTierDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-table-tiers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-table-tiers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SettingsService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<SettingSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/settings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<SettingSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static save(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateSettingRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<SettingSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StatusService {
  /**
   *
   */
  static get(options: IRequestOptions = {}): Promise<Response<string>> {
    return new Promise((resolve, reject) => {
      let url = '/api/status';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UsersService {
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserGetDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      claims?: string[];
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<UserSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/users';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Claims: params['claims'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePassword(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateUserPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserGetDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/users/{id}/password-update';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendPasswordReset(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/users/{id}/send-reset';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AdvancedQueryColumnFilter {
  /**  */
  'fieldName': string;

  /**  */
  'fieldValue': string;

  /**  */
  'exactMatch': boolean;

  constructor(data: undefined | any = {}) {
    this['fieldName'] = data['fieldName'];
    this['fieldValue'] = data['fieldValue'];
    this['exactMatch'] = data['exactMatch'];
  }
}

export class AdvancedQueryColumnSort {
  /**  */
  'fieldName': string;

  /**  */
  'descending': boolean;

  constructor(data: undefined | any = {}) {
    this['fieldName'] = data['fieldName'];
    this['descending'] = data['descending'];
  }
}

export class AccountSummaryDto {
  /**  */
  'id': number;

  /**  */
  'accountNumber': string;

  /**  */
  'name': string;

  /**  */
  'email': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'addressLine1': string;

  /**  */
  'addressLine2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['accountNumber'] = data['accountNumber'];
    this['name'] = data['name'];
    this['email'] = data['email'];
    this['phoneNumber'] = data['phoneNumber'];
    this['addressLine1'] = data['addressLine1'];
    this['addressLine2'] = data['addressLine2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
  }
}

export class Error {
  /**  */
  'propertyName': string;

  /**  */
  'errorMessage': string;

  constructor(data: undefined | any = {}) {
    this['propertyName'] = data['propertyName'];
    this['errorMessage'] = data['errorMessage'];
  }
}

export class CreateAccountRequest {
  /**  */
  'organizationId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'name': string;

  /**  */
  'email': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'addressLine1': string;

  /**  */
  'addressLine2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  constructor(data: undefined | any = {}) {
    this['organizationId'] = data['organizationId'];
    this['accountNumber'] = data['accountNumber'];
    this['name'] = data['name'];
    this['email'] = data['email'];
    this['phoneNumber'] = data['phoneNumber'];
    this['addressLine1'] = data['addressLine1'];
    this['addressLine2'] = data['addressLine2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
  }
}

export class MeterReadingSummaryDto {
  /**  */
  'id': number;

  /**  */
  'accountId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'accountName': string;

  /**  */
  'readingDate': string;

  /**  */
  'readingAmount': string;

  /**  */
  'comments': string;

  /**  */
  'amountDue': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['accountId'] = data['accountId'];
    this['accountNumber'] = data['accountNumber'];
    this['accountName'] = data['accountName'];
    this['readingDate'] = data['readingDate'];
    this['readingAmount'] = data['readingAmount'];
    this['comments'] = data['comments'];
    this['amountDue'] = data['amountDue'];
  }
}

export class AccountDetailDto {
  /**  */
  'meterReadings': MeterReadingSummaryDto[];

  /**  */
  'id': number;

  /**  */
  'accountNumber': string;

  /**  */
  'name': string;

  /**  */
  'email': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'addressLine1': string;

  /**  */
  'addressLine2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  constructor(data: undefined | any = {}) {
    this['meterReadings'] = data['meterReadings'];
    this['id'] = data['id'];
    this['accountNumber'] = data['accountNumber'];
    this['name'] = data['name'];
    this['email'] = data['email'];
    this['phoneNumber'] = data['phoneNumber'];
    this['addressLine1'] = data['addressLine1'];
    this['addressLine2'] = data['addressLine2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
  }
}

export class UpdateAccountRequest {
  /**  */
  'accountNumber': string;

  /**  */
  'name': string;

  /**  */
  'email': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'addressLine1': string;

  /**  */
  'addressLine2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  constructor(data: undefined | any = {}) {
    this['accountNumber'] = data['accountNumber'];
    this['name'] = data['name'];
    this['email'] = data['email'];
    this['phoneNumber'] = data['phoneNumber'];
    this['addressLine1'] = data['addressLine1'];
    this['addressLine2'] = data['addressLine2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
  }
}

export class EmptyResponse {
  /**  */
  'hasErrors': boolean;

  /**  */
  'errors': Error[];

  constructor(data: undefined | any = {}) {
    this['hasErrors'] = data['hasErrors'];
    this['errors'] = data['errors'];
  }
}

export class UploadFileResponse {
  /**  */
  'uri': string;

  constructor(data: undefined | any = {}) {
    this['uri'] = data['uri'];
  }
}

export class Currency {
  /**  */
  'value': number;

  /**  */
  'code': string;

  constructor(data: undefined | any = {}) {
    this['value'] = data['value'];
    this['code'] = data['code'];
  }
}

export class CreateMeterReadingRequest {
  /**  */
  'accountId': number;

  /**  */
  'readingDate': Date;

  /**  */
  'readingAmount': number;

  /**  */
  'comments': string;

  /**  */
  'amountDue': Currency;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['readingDate'] = data['readingDate'];
    this['readingAmount'] = data['readingAmount'];
    this['comments'] = data['comments'];
    this['amountDue'] = data['amountDue'];
  }
}

export class MeterReadingDetailDto {
  /**  */
  'id': number;

  /**  */
  'accountId': number;

  /**  */
  'readingDate': Date;

  /**  */
  'readingAmount': number;

  /**  */
  'comments': string;

  /**  */
  'amountDue': Currency;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['accountId'] = data['accountId'];
    this['readingDate'] = data['readingDate'];
    this['readingAmount'] = data['readingAmount'];
    this['comments'] = data['comments'];
    this['amountDue'] = data['amountDue'];
  }
}

export class UpdateMeterReadingRequest {
  /**  */
  'readingDate': Date;

  /**  */
  'readingAmount': number;

  /**  */
  'comments': string;

  /**  */
  'amountDue': Currency;

  constructor(data: undefined | any = {}) {
    this['readingDate'] = data['readingDate'];
    this['readingAmount'] = data['readingAmount'];
    this['comments'] = data['comments'];
    this['amountDue'] = data['amountDue'];
  }
}

export class OrganizationSummaryDto {
  /**  */
  'id': number;

  /**  */
  'name': string;

  /**  */
  'addressLine1': string;

  /**  */
  'addressLine2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['addressLine1'] = data['addressLine1'];
    this['addressLine2'] = data['addressLine2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
  }
}

export class CreateOrganizationRequest {
  /**  */
  'name': string;

  /**  */
  'addressLine1': string;

  /**  */
  'addressLine2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['addressLine1'] = data['addressLine1'];
    this['addressLine2'] = data['addressLine2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
  }
}

export class OrganizationDetailDto {
  /**  */
  'id': number;

  /**  */
  'name': string;

  /**  */
  'addressLine1': string;

  /**  */
  'addressLine2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['addressLine1'] = data['addressLine1'];
    this['addressLine2'] = data['addressLine2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
  }
}

export class UpdateOrganizationRequest {
  /**  */
  'name': string;

  /**  */
  'addressLine1': string;

  /**  */
  'addressLine2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['addressLine1'] = data['addressLine1'];
    this['addressLine2'] = data['addressLine2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
  }
}

export class OrganizationContextDto {
  /**  */
  'id': number;

  /**  */
  'name': string;

  /**  */
  'addressLine1': string;

  /**  */
  'addressLine2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['addressLine1'] = data['addressLine1'];
    this['addressLine2'] = data['addressLine2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
  }
}

export class RateTableSummaryDto {
  /**  */
  'id': number;

  /**  */
  'effectiveDate': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['effectiveDate'] = data['effectiveDate'];
  }
}

export class CreateRateTableRequest {
  /**  */
  'effectiveDate': Date;

  constructor(data: undefined | any = {}) {
    this['effectiveDate'] = data['effectiveDate'];
  }
}

export class RateTableDetailDto {
  /**  */
  'id': number;

  /**  */
  'effectiveDate': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['effectiveDate'] = data['effectiveDate'];
  }
}

export class UpdateRateTableRequest {
  /**  */
  'effectiveDate': Date;

  constructor(data: undefined | any = {}) {
    this['effectiveDate'] = data['effectiveDate'];
  }
}

export class RateTableTierSummaryDto {
  /**  */
  'id': number;

  /**  */
  'startingAmountForSorting': string;

  /**  */
  'startingAmount': string;

  /**  */
  'endingAmount': string;

  /**  */
  'costIncrement': number;

  /**  */
  'costPerIncrement': string;

  /**  */
  'fixedCost': string;

  /**  */
  'maxCostForTier': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['startingAmountForSorting'] = data['startingAmountForSorting'];
    this['startingAmount'] = data['startingAmount'];
    this['endingAmount'] = data['endingAmount'];
    this['costIncrement'] = data['costIncrement'];
    this['costPerIncrement'] = data['costPerIncrement'];
    this['fixedCost'] = data['fixedCost'];
    this['maxCostForTier'] = data['maxCostForTier'];
  }
}

export class CreateRateTableTierRequest {
  /**  */
  'organizationId': number;

  /**  */
  'startingAmount': number;

  /**  */
  'endingAmount': number;

  /**  */
  'costIncrement': number;

  /**  */
  'costPerIncrement': Currency;

  /**  */
  'fixedCost': Currency;

  constructor(data: undefined | any = {}) {
    this['organizationId'] = data['organizationId'];
    this['startingAmount'] = data['startingAmount'];
    this['endingAmount'] = data['endingAmount'];
    this['costIncrement'] = data['costIncrement'];
    this['costPerIncrement'] = data['costPerIncrement'];
    this['fixedCost'] = data['fixedCost'];
  }
}

export class RateTableTierDetailDto {
  /**  */
  'id': number;

  /**  */
  'startingAmountForSorting': string;

  /**  */
  'startingAmount': number;

  /**  */
  'endingAmount': number;

  /**  */
  'costIncrement': number;

  /**  */
  'costPerIncrement': Currency;

  /**  */
  'fixedCost': Currency;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['startingAmountForSorting'] = data['startingAmountForSorting'];
    this['startingAmount'] = data['startingAmount'];
    this['endingAmount'] = data['endingAmount'];
    this['costIncrement'] = data['costIncrement'];
    this['costPerIncrement'] = data['costPerIncrement'];
    this['fixedCost'] = data['fixedCost'];
  }
}

export class UpdateRateTableTierRequest {
  /**  */
  'startingAmount': number;

  /**  */
  'endingAmount': number;

  /**  */
  'costIncrement': number;

  /**  */
  'costPerIncrement': Currency;

  /**  */
  'fixedCost': Currency;

  constructor(data: undefined | any = {}) {
    this['startingAmount'] = data['startingAmount'];
    this['endingAmount'] = data['endingAmount'];
    this['costIncrement'] = data['costIncrement'];
    this['costPerIncrement'] = data['costPerIncrement'];
    this['fixedCost'] = data['fixedCost'];
  }
}

export class SettingSummaryDto {
  /**  */
  'id': number;

  /**  */
  'key': string;

  /**  */
  'type': string;

  /**  */
  'enum': string;

  /**  */
  'value': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['key'] = data['key'];
    this['type'] = data['type'];
    this['enum'] = data['enum'];
    this['value'] = data['value'];
  }
}

export class UpdateSettingRequest {
  /**  */
  'value': string;

  constructor(data: undefined | any = {}) {
    this['value'] = data['value'];
  }
}

export class CreateUserRequest {
  /**  */
  'claims': string[];

  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'role': string;

  constructor(data: undefined | any = {}) {
    this['claims'] = data['claims'];
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['role'] = data['role'];
  }
}

export class UserGetDto {
  /**  */
  'id': number;

  /**  */
  'organizationId': number;

  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'role': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['organizationId'] = data['organizationId'];
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['role'] = data['role'];
  }
}

export class UserSummaryDto {
  /**  */
  'id': number;

  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'role': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['role'] = data['role'];
  }
}

export class UserDetailDto {
  /**  */
  'claims': string[];

  /**  */
  'id': number;

  /**  */
  'organizationId': number;

  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'role': string;

  constructor(data: undefined | any = {}) {
    this['claims'] = data['claims'];
    this['id'] = data['id'];
    this['organizationId'] = data['organizationId'];
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['role'] = data['role'];
  }
}

export class UpdateUserRequest {
  /**  */
  'claims': string[];

  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'role': string;

  constructor(data: undefined | any = {}) {
    this['claims'] = data['claims'];
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['role'] = data['role'];
  }
}

export class UpdateUserPasswordRequest {
  /**  */
  'confirmNewPassword': string;

  /**  */
  'currentPassword': string;

  /**  */
  'newPassword': string;

  constructor(data: undefined | any = {}) {
    this['confirmNewPassword'] = data['confirmNewPassword'];
    this['currentPassword'] = data['currentPassword'];
    this['newPassword'] = data['newPassword'];
  }
}
