import React from 'react';
import {Dropdown, StrictDropdownProps} from 'semantic-ui-react';
import {getEnumDropdownOptions} from '../../api/generated/utils';
import {AnyObject} from '../../types';

export type DropdownAdapterProps = StrictDropdownProps & {
  enum?: AnyObject;
};

export const DropdownAdapter = ({input, meta, ...rest}) => {
  const {
    options,
    enum: enumObject,
    ...dropdownProps
  } = rest as DropdownAdapterProps;
  const _options = enumObject ? getEnumDropdownOptions(enumObject) : options;

  return (
    <Dropdown
      {...input}
      {...dropdownProps}
      options={_options}
      onChange={(event, {value}) => input.onChange(value)}
    />
  );
};
