import React from 'react';
import moment from 'moment';

type DateProps = {
  date: number | Date | string;
  format?: string;
};

type TimeProps = {
  time: number | Date | string;
  format?: string;
};

export const DateFormat = React.memo<DateProps>(
  ({date, format = 'MM/DD/YY'}) => <>{moment(date).format(format)}</>
);

export const TimeFormat = React.memo<TimeProps>(
  ({time, format = 'hh:mm A'}) => <>{moment(time, 'LT').format(format)}</>
);
