import {analtyics} from '../analytics';
import {ClientIds} from '../api/generated/enums';
import {Env} from '../config/env-vars';
import {notify} from '../hooks/use-subscription';
import {User} from '../types';
import {logger} from '../utils/logger';
import oidcClient, {
  UserManagerSettings,
  User as OidcUser,
  UserManager,
} from 'oidc-client';

const log = logger('oidc', {namespace: 'oidc-client'});
oidcClient.Log.logger = log;

const settings = {
  client_id: ClientIds.web,
  authority: Env.apiBaseUrl || '',
  response_type: 'id_token token',
  scope: `openid profile Elevator3.Billing.permissions Elevator3.Billing.api`,
  redirect_uri: `${Env.appRoot}/callback`,
  filterProtocolClaims: true,
  post_logout_redirect_uri: Env.appRoot,
  silent_redirect_uri: `${Env.appRoot}/silent-refresh.html`,
  automaticSilentRenew: true,
} as UserManagerSettings;

export const userManager = new UserManager(settings);

userManager.events.addSilentRenewError(() => {
  analtyics.trackEvent('identity.session-expired');
  notify('session-expired', undefined);
});

userManager.events.addUserSignedOut(async () => {
  await userManager.removeUser();
  window.location.reload();
});

export const getUser = async (): Promise<OidcUser | null> => {
  const user = await userManager.getUser();
  return !user?.expired ? user : null;
};

export const mapOidcUser = (user: OidcUser): User => ({
  id: Number(user.profile.sub),
  name: user.profile.name || '',
  permissions: user.profile.permissions,
  role: user.profile.role,
});
