import React, {useState} from 'react';
import {Icon, Popup, PopupProps} from 'semantic-ui-react';
import {Button} from '../forms/button';

type DeleteButton = Omit<Button, 'type' | 'as'> & {
  onConfirm: () => void;
  loading?: boolean;
  text?: string;
  confirmPosition?: PopupProps['position'];
  loadingText?: string;
  confirmText?: string;
  icon?: boolean;
};

export const DeleteButton = ({
  onConfirm,
  loading,
  text = 'Delete',
  loadingText = 'Deleting...',
  confirmText = 'Are you sure?',
  confirmPosition = 'right center',
  icon,
  ...props
}: DeleteButton) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onConfirmClick = () => {
    onConfirm();
    onClose();
  };

  return (
    <Popup
      open={open}
      onClose={onClose}
      trigger={
        <Button
          type="button"
          icon={icon}
          negative
          disabled={loading}
          {...props}
          onClick={toggleOpen}
        >
          {icon && <Icon name="trash" color="red" />}
          {icon && ' '}
          <span>{loading ? loadingText : text}</span>
        </Button>
      }
      content={
        <Button
          type="button"
          negative
          onClick={onConfirmClick}
          disabled={loading}
        >
          {confirmText}
        </Button>
      }
      position={confirmPosition}
      on="click"
    />
  );
};
