import {snakeCase, upperFirst} from 'lodash';
import {Optional} from 'utility-types';
import {guard} from './guard';
import {debug} from './logger';
import {AnyObject, KeyOf} from '../types';
import {
  AdvancedQueryColumnFilter,
  AdvancedQueryColumnSort,
} from '../api/generated';

const DEFAULT_VALUE = {};
const parse = guard((input: string) => JSON.parse(input), DEFAULT_VALUE);

export function parseJson<T>(input: string | undefined | null): T | undefined {
  if (!input) {
    debug.warn('empty json input:', input);
    return undefined;
  }

  const object = parse(input);
  if (object === DEFAULT_VALUE) {
    debug.warn('invalid json input:', input);
    return undefined;
  }

  return object;
}

export function parseEncodedJson<T>(
  input: string | undefined | null
): T | undefined {
  if (!input) {
    return undefined;
  }

  try {
    return parseJson<T>(window.atob(input));
  } catch (error) {
    return undefined;
  }
}

export function encodeJson(object: AnyObject): string {
  try {
    return window.btoa(JSON.stringify(object));
  } catch (error) {
    return '';
  }
}

export const titleCase = input =>
  snakeCase(input)
    .split('_')
    .map(upperFirst)
    .join(' ');

export interface AdvancedPagedSearch<T> {
  searchFieldNames: KeyOf<T>[];
  searchSearchText: string;
  searchExactMatch?: boolean;
}

export type AdvancedPagedRequest<T> = {
  page?: number;
  pageSize?: number;
  filtered?: AdvancedQueryColumnFilter[];
  sorted?: AdvancedQueryColumnSort[];
} & Optional<AdvancedPagedSearch<T>>;

export interface AdvancedPagedResult<T> {
  items: T[];
  page: number;
  pageSize: number;
  pageCount: number;
  totalItemCount: number;
}

export function normalizedAdvancedPagedResult<T>(
  value: undefined | AdvancedPagedResult<T>
): AdvancedPagedResult<T> {
  return (
    value || {
      items: [],
      page: 0,
      pageSize: 0,
      pageCount: 0,
      totalItemCount: 0,
    }
  );
}
