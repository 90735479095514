import {FORM_ERROR} from 'final-form';
import {Response} from '../api/generated';

import {
  camelCase,
  isPlainObject,
  keys,
  merge,
  reduce,
  set,
  toPath,
} from 'lodash';

const flattenKeys = (obj, path: string[] = []) =>
  !isPlainObject(obj)
    ? {[path.join('.')]: obj}
    : reduce(
        obj,
        (acc, next, key) => merge(acc, flattenKeys(next, [...path, key])),
        {}
      );

export const getNestedKeys = object => {
  return keys(flattenKeys(object)).map(x => x || FORM_ERROR);
};

export const mapErrors = (response: Response<any>) => {
  return response.errors.reduce((errors, error) => {
    const errorPath = toPath(error.propertyName).map(camelCase);
    const errorMessage = error.errorMessage.replace(/'/gi, '');
    return set(errors, errorPath, errorMessage);
  }, {});
};

export const getErrorMessages = (response: Response<any>) =>
  response.errors.map(error => error.errorMessage.replace(/'/gi, ''));
